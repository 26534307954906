<template>
    <div class="breadcrumbs-container">
        <nav class="breadcrumbs">
            <ul class="breadcrumbs__list">
                <template v-if="collapseList">
                    <li class="breadcrumbs__list-item">
                        <WatsonAtomBreadcrumbItem
                            :label="items[0].label"
                            :url="items[0].url || mainUrl(0)"
                            :icon-after="true"
                            :truncation-width="40"
                        />
                    </li>
                    <li class="breadcrumbs__list-item">
                        <button
                            class="breadcrumbs__list-item-collapse"
                            @click="collapseList = false"
                        >
                            <span class="u-tiny-text u-bold">
                                ...
                            </span>
                        </button>

                        <FontAwesomeIcon :icon="faChevronRight" class="breadcrumbs__list-item-icon" />
                    </li>
                    <li class="breadcrumbs__list-item">
                        <WatsonAtomBreadcrumbItem
                            :label="items[items.length - 2].label"
                            :url="items[items.length - 2].url || mainUrl(items.length - 2)"
                            :icon-after="true"
                            :truncation-width="40"
                        />
                    </li>
                    <li class="breadcrumbs__list-item">
                        <WatsonAtomBreadcrumbItem
                            :label="items[items.length - 1].label"
                            :truncation-width="40"
                        />
                    </li>
                </template>
                <template v-else>
                    <template v-for="(item, index) in items">
                        <li
                            v-if="index < items.length - 1"
                            class="breadcrumbs__list-item"
                        >
                            <WatsonAtomBreadcrumbItem
                                :label="item.label"
                                :url="item.url || mainUrl(index)"
                                :icon-after="true"
                            />
                        </li>
                    </template>
                    <li class="breadcrumbs__list-item">
                        <WatsonAtomBreadcrumbItem
                            :label="items[items.length - 1].label"
                        />
                    </li>
                </template>
            </ul>
        </nav>

        <!-- eslint-disable vue/no-v-html -->
        <div
            v-if="structuredData"
            v-html="`<script type=&quot;application/ld+json\&quot;>${JSON.stringify(structuredData)}</script>`"
        ></div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import WatsonAtomBreadcrumbItem from '@atoms/breadcrumb-item/watson-atom-breadcrumb-item.vue';

const props = defineProps({
    items: {
        type: Array,
        required: true,
    },
});

const page = usePage();

const collapseList = ref(false);

if (props.items.length > 3) {
    collapseList.value = true;
}

const mainUrl = index => {
    let currentUrl = page.props.url;
    let slugs = page.props.slugs;

    for (let i = slugs.length; i >= index; i--) {
        currentUrl = currentUrl.replace(`/${slugs[i]}`, '');
    }

    return currentUrl;
};

const generateStructuredData = items => ({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': items.map((item, index) => ({
        '@type': 'ListItem',
        'position': index + 1,
        'item': {
            '@id': item.url || mainUrl(index),
            'name': item.label,
        },
    })),
});

const structuredData = generateStructuredData(props.items);
</script>

<style lang="scss" scoped>
@use 'watson-organism-breadcrumbs';
</style>
