<template>
    <div class="breadcrumb-item">
        <FontAwesomeIcon
            v-if="iconBefore"
            :icon="faChevronLeft"
            class="breadcrumb-item__icon breadcrumb-item__icon--before"
        />

        <component
            :is="url ? 'Link' : 'span'"
            :href="url"
            :title="decodeHtmlEntities(label)"
            class="breadcrumb-item__label u-tiny-text"
            :class="{
                'breadcrumb-item__label--truncated': truncationWidth,
                'u-bold': url
            }"
        >
            {{ decodeHtmlEntities(label) }}
        </component>

        <FontAwesomeIcon
            v-if="iconAfter"
            :icon="faChevronRight"
            class="breadcrumb-item__icon breadcrumb-item__icon--after"
        />
    </div>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import decodeHtmlEntities from '@utils/decode-html-entities';

const props = defineProps({
    label: {
        type: String,
        required: true,
    },
    url: {
        type: String,
        required: false,
        default: null,
    },
    iconBefore: {
        type: Boolean,
        required: false,
        default: false,
    },
    iconAfter: {
        type: Boolean,
        required: false,
        default: false,
    },
    truncationWidth: {
        type: Number,
        required: false,
        default: null,
    },
    styling: {
        type: Object,
        required: false,
        default: () => ({}),
    },
});
</script>

<style lang="scss" scoped>
@use 'watson-atom-breadcrumb-item';

@layer atom {
    .breadcrumb-item {
        --text-color: v-bind('props.styling.textColor || null');
        --icon-color: v-bind('props.styling.iconColor || null');
        --link-color: v-bind('props.styling.linkColor || null');
        --link-hover-color: v-bind('props.styling.linkHoverColor || null');
        --link-active-color: v-bind('props.styling.linkActiveColor || null');
        --truncation-width: v-bind('props.truncationWidth ? props.truncationWidth + "ch" : null');
    }
}
</style>
